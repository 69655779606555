@import '../../styles/variables';
@import '../../styles/mixins';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  @include media-breakpoint-down(sm) {
    text-align: center;
    margin: 0 auto;
  }
  .cta {
    margin-top: 85px;
    @include media-breakpoint-down(sm) {
      margin-top: 35px;
    }
  }
  .countdownTimer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .unit {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      width: 130px;
      @include media-breakpoint-down(lg) {
        width: 100px;
      }
      @include media-breakpoint-down(sm) {
        width: 55px;
      }

      &.expired {
        color: $grey-feature;
      }
    }
    .unitName {
      font-family: $sofiapro;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      text-transform: uppercase;
      @include media-breakpoint-down(ss) {
        font-size: 13px;
        line-height: 16px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 13px;
        line-height: 16px;
      }
    }
    .digit {
      font-family: $harrybeastdisplay;
      font-size: 118px;
      line-height: 118px;
      @include media-breakpoint-down(lg) {
        font-size: 80px;
        line-height: 80px;
      }
      @include media-breakpoint-down(ss) {
        font-size: 42px;
        line-height: 42px;
      }
    }
    img {
      margin: 0 75px;
      height: 146px;
      @include media-breakpoint-down(lg) {
        margin: 0 30px;
        height: 100px;
      }
      @include media-breakpoint-down(ss) {
        margin: 0 15px;
        height: 53px;
      }
    }
  }
}
