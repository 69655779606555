@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.root {
  width: 100%;
  margin: 0 auto;
  max-width: $homepage-max-width;
  padding: $homepage-vertical-padding $homepage-padding $homepage-vertical-padding-extra;
  box-sizing: border-box;

  @include media-breakpoint-down(md) {
    padding-bottom: 160px;
  }

  .promosContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 50px;
    grid-column-gap: 34px;

    &> * {
      flex-basis: calc(33% - 20px);
      @include media-breakpoint-down(xm) {
        flex-basis: calc(33% - 22px);
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      grid-row-gap: 35px;
      flex-wrap: unset;
    }
  }
}

