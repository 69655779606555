@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.root {
  margin: 0 auto;
  padding: $homepage-vertical-padding 20px 100px;
  max-width: $homepage-max-width;
  box-sizing: border-box;

  @include media-breakpoint-down(md) {
    max-width: 100%;
    padding: $homepage-vertical-padding 20px 180px;
  }
  @include media-breakpoint-down(sm) {
    padding: $homepage-vertical-padding 20px 150px;
  }

  .twoColumn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .contentList,
    .poll,
    .flexibleContainerImageCTA {
      flex: 1;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .poll {
      display: flex;
      flex-direction: column;
      max-width: 530px;
      margin-left: unset;
      margin-right: 30px;
      align-items: flex-start;

      &>* {
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }

      @include media-breakpoint-down(md) {
        margin: 0 auto 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .contentList + .poll {
    margin-right: unset;
    margin-left: 30px;
    align-items: flex-start;

    @include media-breakpoint-down(md) {
      margin: 50px auto;
    }
  }

  .subHeader {
    font-family: $sofiapro;
    font-size: 18px;
    line-height: 20px;
    font-weight: 300;
    letter-spacing: 0.3px;
    text-align: center;
  }
}
