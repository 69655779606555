@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$backgroundWidth: 2000px;
$mobileBackgroundWidth: 100%;

.root {
  position: relative;

  .row {
    position: relative;
    display: flex;
    flex-direction: column;
    
    .rowComponent {
      min-height: 400px;
      flex: 1 1 auto;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: $mobileBackgroundWidth auto, ($backgroundWidth * 0.75) auto, 100% 100%;
      background-position:  50% 101%, center center, bottom center;
      display: flex;
      align-items: center;
      
      @include media-breakpoint-up(md) {
        background-size: ($backgroundWidth * 0.8) auto, ($backgroundWidth * 0.8) auto, 100% 100%;
      }
      
      @include media-breakpoint-up(lg) {
        background-position:  50% 101%, center center, bottom center !important;
        background-size: $backgroundWidth auto, $backgroundWidth auto, 100% 100%;
      }
    }

    .fullWidthPromo {
      display: flex;
    }

    .anchorPosition {
      display: block;
      height: $header-height;
      margin-top: ($header-height * -1);
      visibility: hidden;
    }
  }

  .rowFirst {
    background: $homeRow1a;

    .rowComponent {
      display: block;
    }
  }

  .rowFirst + .row1 {
    .rowComponent {
      z-index: 10;
      
      & > div {
        padding-top: 0;

        @include media-breakpoint-up(md) {
          margin-top: -25px;          
        }
      }
    }
  }

  .rowLast {
    .rowComponent {
      & > div {
        padding-bottom: 100px;
      }
    }
  }

  .row1 {
    .rowComponent {
      background-image:  url('/images/home/horizons_mobile_village.png'), url('/images/home/stars.png'), linear-gradient(180deg, $homeRow1a 0%, $homeRow1b 121.35%);
      
      @include media-breakpoint-up(md) {
        background-image:  url('/images/home/horizons_village.png'), url('/images/home/stars.png'), linear-gradient(180deg, $homeRow1a 0%, $homeRow1b 121.35%);
        background-position: center 101%, center center, bottom center;
      }
    }
    .fullWidthPromo {
      background: linear-gradient(180deg, $homeRow0Banner 0%, $black 100%);
    }
    &.rowLast {
      .rowComponent {
        background-image: url('/images/home/stars.png'), linear-gradient(180deg, $homeRow1a 0%, $homeRow1b 121.35%);
        background-position: center center, bottom center;
        background-size: ($backgroundWidth * 0.75) auto, 100% 100%;
      }
    }
  }
  
  .row2 {
    .rowComponent {
      background-image: url('/images/home/horizons_mobile_train.png'), url('/images/home/stars.png'), linear-gradient(180deg, $homeRow2a 0%, $homeRow2b 121.35%);
      
      @include media-breakpoint-up(md) {
        background-image: url('/images/home/horizons_train.png'), url('/images/home/stars.png'), linear-gradient(180deg, $homeRow2a 0%, $homeRow2b 121.35%);
        background-position: center 101%, center center, bottom center;
      }
    }
    .fullWidthPromo {
      background: linear-gradient(180deg, $homeRow1Banner 0%, $black 100%);
    }
    &.rowLast {
      .rowComponent {
        background-image: url('/images/home/stars.png'), linear-gradient(180deg, $homeRow2a 0%, $homeRow2b 121.35%);
        background-position: center center, bottom center;
        background-size: ($backgroundWidth * 0.75) auto, 100% 100%;
      }
    }
  }
  
  .row3 {
    .rowComponent {
      background-image: url('/images/home/horizons_mobile_hogwarts.png'), url('/images/home/stars.png'), linear-gradient(180deg, $homeRow3a 0%, $homeRow3b 121.35%);
      
      @include media-breakpoint-up(md) {
        background-image: url('/images/home/horizons_hogwarts.png'), url('/images/home/stars.png'), linear-gradient(180deg, $homeRow3a 0%, $homeRow3b 121.35%);
        background-position: right 101%, center center, bottom center;
      }
    }
    .fullWidthPromo {
      background: linear-gradient(180deg, $homeRow2Banner 0%, $black 100%);
    }
    &.rowLast {
      .rowComponent {
        background-image: url('/images/home/stars.png'), linear-gradient(180deg, $homeRow3a 0%, $homeRow3b 121.35%);
        background-position: center center, bottom center;
        background-size: ($backgroundWidth * 0.75) auto, 100% 100%;
      }
    }
  }
  
  .row4 {
    .rowComponent {
      background-image: url('/images/home/horizons_mobile_quidditch.png'), url('/images/home/stars.png'), linear-gradient(180deg, $homeRow4a 0%, $homeRow4b 121.35%);

      @include media-breakpoint-up(md) {
        background-image: url('/images/home/horizons_quidditch.png'), url('/images/home/stars.png'), linear-gradient(180deg, $homeRow4a 0%, $homeRow4b 121.35%);
        background-position: left 101%, center center, bottom center;
      }
    }
    .fullWidthPromo {
      background: linear-gradient(180deg, $homeRow3Banner 0%, $black 100%);
    }
    &.rowLast {
      .rowComponent {
        background-image: url('/images/home/stars.png'), linear-gradient(180deg, $homeRow4a 0%, $homeRow4b 121.35%);
        background-position: center center, bottom center;
        background-size: ($backgroundWidth * 0.75) auto, 100% 100%;
      }
    }
  }
  
  .row5 {
    .rowComponent {
      background-image: url('/images/home/horizons_mobile_bridge.png'), url('/images/home/stars.png'), linear-gradient(180deg, $homeRow5a 0%, $homeRow5b 121.35%);
      
      @include media-breakpoint-up(md) {
        background-image: url('/images/home/horizons_bridge.png'), url('/images/home/stars.png'), linear-gradient(180deg, $homeRow5a 0%, $homeRow5b 121.35%);
        background-position: center 101%, center center, bottom center;
      }
    }
    .fullWidthPromo {
      background: linear-gradient(180deg, $homeRow4Banner 0%, $black 100%);
    }
    &.rowLast {
      .rowComponent {
        background-image: url('/images/home/stars.png'), linear-gradient(180deg, $homeRow5a 0%, $homeRow5b 121.35%);
        background-position: center center, bottom center;
        background-size: ($backgroundWidth * 0.75) auto, 100% 100%;
      }
    }
  }
  
  .row0 {
    .rowComponent {
      background-image: url('/images/home/horizons_mobile_burrow.png'), url('/images/home/stars.png'), linear-gradient(180deg, $homeRow0a 0%, $homeRow0b 121.35%);
      
      @include media-breakpoint-up(md) {
        background-image: url('/images/home/horizons_burrow.png'), url('/images/home/stars.png'), linear-gradient(180deg, $homeRow0a 0%, $homeRow0b 121.35%);
        background-position: right 101%, center center, bottom center;
      }
    }
    .fullWidthPromo {
      background: linear-gradient(180deg, $homeRow5Banner 0%, $black 100%);
    }
    &.rowLast {
      .rowComponent {
        background-image: url('/images/home/stars.png'), linear-gradient(180deg, $homeRow0a 0%, $homeRow0b 121.35%);
        background-position: center center, bottom center;
        background-size: ($backgroundWidth * 0.75) auto, 100% 100%;
      }
    }
  }

  .countdown {
    padding-bottom: 100px;
    @include media-breakpoint-down(sm) {
      padding-bottom: 50px;
    }
  }
}
